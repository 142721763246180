<template>
    <Card class="mt-4">
      <template #content>
        <div>
          <UploadFile :upload-action="doUpload" :uploaded-files="uploadedFiles" :delete-file-action="deleteFile"
                      :delete-button-enabled="false"
                      :download-button-enabled="false"
                      :action-column-enabled="false"
                      context="contratto"/>
        </div>
      </template>

      <template #footer>
        <div class="grid justify-content-end">
          <Button :label="t('action.fine')" @click="complete()" icon="pi pi-angle-right" iconPos="right"/>
        </div>
      </template>

    </Card>
</template>

<script setup>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import useMessage from "@/composable/useMessage";
import UploadFile from "@/components/common/UploadFile";
import {useI18n} from "vue-i18n";

const emit = defineEmits(['complete']);

const store = useStore();
const message = useMessage();
const {t} = useI18n();

const contratto = store.getters['contrattoWizard/contratto'];
const uploadedFiles = ref([]);
computed(() => store.getters['contratto/uploadedFiles']);


const complete = () => {
  emit('complete', {pageIndex: 3});
};

const doUpload = async (files) => {
  await store.dispatch("contratto/uploadFiles", {contratto, documentType: files.documentType, files});
  uploadedFiles.value = store.getters['contratto/uploadedFiles'];
  message.successWithKey("message.upload-success");
}

const deleteFile = async () => {

}

</script>
